<template>
  <div class="container mx-auto dynamic-view">
    <h1 class="h1-title" v-if="page">{{ page.title }}</h1>
    <div v-if="page">
      <div v-for="(block, index) in page.body" :key="index" class="block">
        <div
          v-if="block.type === 'collapsible'"
          class="collapsible-block-wrapper"
        >
          <CollapsibleBlock
            :block="block"
            :citationMap="citationMap"
            :visibilityMap="visibilityMap"
            :index="index"
          />
        </div>
        <div class="dynamic-block-wrapper" v-else>
          <DynamicBlock
            :block="block"
            :citationMap="citationMap"
            :customSlug="customSlug"
          />
        </div>
        <hr v-if="index < page.body.length - 1" class="block-separator" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DynamicBlock from '../components/DynamicBlock.vue';
import CollapsibleBlock from '../components/dynamic-blocks/CollapsibleBlock.vue';
import { inject } from 'vue';

export default {
  components: {
    DynamicBlock,
    CollapsibleBlock,
  },
  props: {
    customSlug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visibilityMap: new Map(),
      citationMap: new Map(),
    };
  },
  setup() {
    const updateCiteType = inject('updateCiteType');
    updateCiteType('static');
    const updatePage = inject('updatePage');
    return { updatePage, updateCiteType };
  },
  apollo: {
    page: {
      query: gql`
        query ($slug: String!) {
          pageBySlug(slug: $slug) {
            id
            title
            slug
            body
          }
        }
      `,
      variables() {
        return {
          slug: this.customSlug || this.$route.params.slug,
        };
      },
      update: (data) => data.pageBySlug,
    },
  },
  watch: {
    page: {
      immediate: true,
      handler(newPage) {
        if (
          newPage &&
          newPage.title &&
          newPage.slug !== 'home' &&
          newPage.slug !== 'news' &&
          newPage.slug !== 'exhibitions'
        ) {
          document.title = `${newPage.title} - MCH`;
        }
        if (newPage && newPage.body) {
          newPage.body.forEach((block) => {
            if (
              block.type === 'collection_Citations' &&
              block.value.Zotero_Collection_ID
            ) {
              this.fetchAndDisplayCitation(block);
            }
            if (block.type === 'collapsible') {
              block.value.content.forEach((innerBlock) => {
                if (
                  innerBlock.type === 'collection_Citations' &&
                  innerBlock.value.Zotero_Collection_ID
                ) {
                  this.fetchAndDisplayCitation(innerBlock);
                }
              });
            }
          });
          this.updateFooter(newPage);
        }
      },
    },
  },
  methods: {
    async fetchAndDisplayCitation(block) {
      try {
        const citationId = block.value.Zotero_Collection_ID;
        const response = await this.$apollo.query({
          query: gql`
            query {
              citationsByCollection(collectionId: "${citationId}") {
                citation
                zotKey
              }
            }
          `,
        });

        const responseData = response.data.citationsByCollection;
        this.citationMap.set(block.value.Zotero_Collection_ID, responseData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching citation:', error);
      }
    },
    updateFooter(newPage) {
      //the news page is a subcomponent of the main page, so we need to properly
      // set the citation type rather than updating the page in that case.
      if (
        newPage === null ||
        newPage.slug === 'news' ||
        newPage.slug === 'exhibitions'
      ) {
        this.updateCiteType('site');
      } else {
        this.updateCiteType('static');
        this.updatePage(newPage);
      }
    },
  },
};
</script>

<style scoped>
@import '../../../css/dynamic.css';
</style>
