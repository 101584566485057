import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import qs from 'qs';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  //use the qs library to parse and stringify query strings to handle nested object in the search page
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
});

// Handle dynamic titles using routes.js meta.title attributes
// For pages with slugs, like Works (detail-view) that is handled by watching the Work
// and updating directly in the component. Otherwise we would need extra API calls
// here to get the title from the slug.
// document.title is also modified in DynamicView.vue
const DEFAULT_TITLE = 'Mapping Color in History';
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
