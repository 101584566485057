function getCSRFToken() {
  let csrfToken = '';
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('csrftoken=')) {
      csrfToken = cookie.substring('csrftoken='.length);
      break;
    }
  }

  // If the CSRF token wasn't found in the cookies, try to get it from a meta tag
  if (!csrfToken) {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    if (metaTag) {
      csrfToken = metaTag.getAttribute('content');
    }
  }
  return csrfToken;
}

export default getCSRFToken;
