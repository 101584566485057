import '@fontsource/heebo';
import '../css/index.css';

import app from './app';

const ready = (fn) => {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

window.ready = ready;

app.mount('#app');
