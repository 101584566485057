<template>
  <div class="flex flex-col methodology">
    <router-link
      :to="{
        name: 'search-view',
        query: {
          'works[refinementList][analyses.methodologies][0]': methodology.name,
        },
      }"
    >
      <h2 class="text-gray-900 text-4xl font-semibold mt-2">
        <span class="methodology-name">{{ methodology.name }}</span> ({{
          methodology.acronym
        }})
      </h2>
    </router-link>
    <p
      v-if="methodology.description"
      class="text-gray-700 font-mono methodology-description"
    >
      {{ methodology.description }}
    </p>
    <template
      v-if="
        methodology.zoteroPublications &&
        methodology.zoteroPublications.length > 0
      "
    >
      <h3 class="text-gray-900 text-lg font-semibold">References</h3>
      <ol class="list-decimal text-sm font-mono tracking-tighter ml-10">
        <li
          class=""
          v-for="publication in methodology.zoteroPublications"
          :key="publication.id"
        >
          <div
            class="text-gray-700"
            v-html="publication.citation"
            :style="{ 'text-indent': '-2em', 'padding-left': '2em' }"
          ></div>
        </li>
      </ol>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    methodology: Object,
  },
};
</script>
