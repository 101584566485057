<template>
  <div class="container top">
    <img
      :src="block.value.pic.url"
      :alt="block.value.pic.title"
      height="100px"
      width="150px"
    />
  </div>
  <b><div class="container middle" v-html="block.value.name"></div></b>
  <b><div class="container middle" v-html="block.value.title"></div></b>
  <div class="container bottom" v-html="block.value.bio"></div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>
