<template>
  <div class="results flex flex-wrap mb-11" v-if="results">
    <ThumbnailResult v-for="work in results" :key="work.id" :result="work" />
  </div>
</template>

<script>
import ThumbnailResult from './ThumbnailResult.vue';

export default {
  props: {
    results: Array,
  },
  components: {
    ThumbnailResult,
  },
};
</script>
