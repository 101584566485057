export function sortCenturies(a, b) {
  const parseCentury = (century) => {
    // Centuries are named like "1st century", "2nd century", etc.
    const match = century.match(/(\d+)(st|nd|rd|th) century/);
    return match ? parseInt(match[1], 10) : century;
  };

  const centuryA = parseCentury(a.name);
  const centuryB = parseCentury(b.name);

  return centuryA - centuryB;
}
