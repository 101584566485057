<template>
  <router-link
    class="mt-5 work-result-item"
    :to="{ name: 'detail-view', params: { identifier: result.slug } }"
  >
    <div
      class="work-result-image-wrapper"
      :class="{ 'has-analysis': result.has_published_analysis }"
    >
      <img
        class="w-full work-result-image"
        :class="[
          noImage ? 'p-5' : '',
          result.has_published_analysis ? 'has-analysis' : '',
        ]"
        :src="imageSrc"
        :alt="noImage ? 'No work image available' : result.title"
      />
    </div>
    <div class="mt-2 work-result-title">{{ result.title }}</div>
    <div class="work-results-location-wrapper">
      <div
        class="text-gray-medium work-result-repository"
        v-if="result.repository"
      >
        {{ result.repository.name }}
      </div>
      <div
        class="text-gray-medium font-mono text-xs my-auto work-result-accessionNumber"
        v-if="result.accession_number"
      >
        {{ result.accession_number }}
      </div>
    </div>
    <div
      class="text-gray-medium work-result-classification"
      v-if="result.classification"
    >
      {{ result.classification.name }}
    </div>
    <div class="text-gray-medium work-result-location" v-if="result.location">
      {{ result.location.name }}
    </div>
    <div class="text-gray-medium work-result-date" v-if="result.creation_date">
      {{ result.creation_date.name }}
    </div>
  </router-link>
</template>

<script>
import PlaceholdImage from '../../../../images/no-image.svg';

export default {
  props: {
    result: Object,
  },
  computed: {
    noImage() {
      return !this.result.thumb_url && !this.result.image_url;
    },
    imageSrc() {
      return this.result.thumb_url || this.result.image_url || PlaceholdImage;
    },
  },
};
</script>

<style scoped>
.work-result-image-wrapper {
  position: relative;
}
.work-result-image.has-analysis {
  display: inline-block;
}

.work-result-image-wrapper.has-analysis::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 30px solid black; /* Adjust size as needed */
  border-left: 30px solid transparent;
  z-index: 1000;
}
</style>
