<template>
  <button
    aria-expanded="isVisible(index)"
    class="btn btn-primary"
    @click="toggleVisibility(index)"
  >
    <div class="button-container">
      <strong>{{ block.value.label }}</strong>
      <svg class="icon" viewBox="0 0 10 10">
        <template v-if="isVisible(index)">
          <path d="M1 5h8" stroke="#000" stroke-width="1" />
        </template>
        <template v-else>
          <path d="M1 5h8M5 1v8" stroke="#000" stroke-width="1" />
        </template>
      </svg>
    </div>
  </button>
  <div v-if="isVisible(index)">
    <div
      v-for="(innerBlock, innerIndex) in block.value.content"
      :key="innerIndex"
      class="collapsible-block"
    >
      <DynamicBlock :block="innerBlock" :citationMap="citationMap" />
      <hr
        v-if="innerIndex < block.value.content.length - 1"
        class="block-separator"
      />
    </div>
  </div>
</template>

<script>
import DynamicBlock from '../DynamicBlock.vue';
export default {
  components: {
    DynamicBlock,
  },
  props: {
    block: Object,
    picUrlMap: Object,
    citationMap: Object,
    visibilityMap: Object,
    index: Number,
  },
  methods: {
    toggleVisibility(index) {
      this.visibilityMap.set(index, !this.isVisible(index));
    },
    isVisible(index) {
      if (this.visibilityMap.has(index)) {
        return this.visibilityMap.get(index);
      } else {
        this.visibilityMap.set(index, false);
        return false;
      }
    },
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 1em;
  height: 1em;
  margin-left: 1rem;
}
</style>
