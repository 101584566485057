<template>
  <div class="container mx-auto">
    <h1 class="h1-title">Browse by Pigments</h1>
    <div class="flex flex-wrap mt-10" id="pigments">
      <span
        class="w-52 h-auto mb-10 mx-4 relative pigment-item"
        v-for="pigment in allPigments"
        :key="pigment.uuid"
      >
        <a
          v-if="pigment.mfaUri"
          class="cursor-pointer cameo-link"
          :href="pigment.mfaUri"
          target="_blank"
        >
          <svg class="w-6 absolute right-8 top-8" viewBox="0 0 25 25">
            <rect x="1" y="1" style="fill: white" width="23" height="23" />
            <path d="M23,2v21H2V2H23 M25,0H0v25h25V0L25,0z" />

            <path
              d="M9.8,18.4c-0.8-0.5-1.5-1.2-2-2.2c-0.5-0.9-0.7-2.1-0.7-3.4s0.2-2.4,0.6-3.4c0.4-0.9,1-1.7,1.9-2.2
              c0.8-0.5,1.8-0.8,2.9-0.8c0.8,0,1.6,0.2,2.2,0.5c0.7,0.3,1.2,0.8,1.6,1.5c0.4,0.6,0.7,1.4,0.9,2.3l-1.9,0.4
              c-0.1-0.6-0.3-1.2-0.5-1.6c-0.3-0.4-0.6-0.8-1-1c-0.4-0.2-0.8-0.3-1.3-0.3c-0.7,0-1.3,0.2-1.8,0.5c-0.5,0.4-0.9,0.9-1.1,1.6
              c-0.3,0.7-0.4,1.5-0.4,2.4c0,0.9,0.1,1.7,0.4,2.4c0.3,0.7,0.7,1.2,1.3,1.6c0.6,0.4,1.2,0.6,2,0.6c0.6,0,1.1-0.1,1.6-0.4
              s0.9-0.7,1.2-1.2c0.3-0.5,0.5-1.1,0.6-1.8l1.9,0.4c-0.1,1-0.4,1.8-0.9,2.5c-0.5,0.7-1.1,1.3-1.9,1.7c-0.8,0.4-1.6,0.6-2.6,0.6
              C11.6,19.2,10.6,18.9,9.8,18.4z"
            />
          </svg>
        </a>
        <router-link
          :to="{
            name: 'search-view',
            query: {
              'works[refinementList][analyses.pigments][0]': pigment.name,
            },
          }"
        >
          <img
            class="pigment"
            :src="pigmentPath"
            :style="{
              backgroundImage: `url(${pigment.pigmentImageUri || 'https://placehold.co/300?text=No+Image'})`,
            }"
          />
          <div class="text-center text-xl">
            {{ pigment.name }}
          </div>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import PigmentPath from '../../../images/pigment-path.png';
import { inject } from 'vue';

export default {
  setup() {
    const updateCiteType = inject('updateCiteType');
    updateCiteType('pigments');
  },
  data() {
    return {
      allPigments: [],
    };
  },
  computed: {
    pigmentPath() {
      return PigmentPath;
    },
  },
  apollo: {
    allPigments: {
      query: gql`
        query Pigments {
          allPigments {
            edges {
              node {
                uuid
                name
                alternateSpellings
                gettyUri
                mfaUri
                pigmentImageUri
              }
            }
          }
        }
      `,
      update: (data) => data.allPigments.edges.map((e) => e.node),
    },
  },
};
</script>

<style scoped>
.pigment {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200%;
}
</style>
