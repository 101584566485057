import { createApp } from 'vue';
import { apolloProvider } from './gql/apollo';

import App from './App.vue';
import router from './router';

import 'vite/modulepreload-polyfill';

import InstantSearch from 'vue-instantsearch/vue3/es';

// Vuetify
// TODO look into Vite plugin for Vuetify for tree-shaking
// https://vuetifyjs.com/en/getting-started/installation/#existing-projects
// https://npmjs.com/package/vite-plugin-vuetify
// import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
});

// configureCompat({ WATCH_ARRAY: false });

const app = createApp(App);
app.use(router);
app.use(apolloProvider);
app.use(InstantSearch);
app.use(vuetify);

export default app;
