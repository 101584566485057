<template>
  <div id="results-map">
    <div class="unmapped" v-if="noLocationCount > 0">
      There are {{ noLocationCount }} result(s) without location information.
    </div>

    <div v-if="mappableResults.length > 0">
      <l-map
        @ready="drawMap"
        v-model:zoom="zoom"
        :minZoom="mapOptions.minZoom"
        :maxZoom="mapOptions.maxZoom"
        :center="center"
        :options="mapOptions"
        ref="map"
        style="height: 800px"
      >
        <l-tile-layer :url="tileUrl" :attribution="attribution" />
      </l-map>
    </div>
    <div
      v-else
      class="text-center my-2 mb-11 bg-gray-100 border border-gray-200 p-8"
    >
      None of the results have mappable locations.
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer } from '@vue-leaflet/vue-leaflet';
import L, { latLng, latLngBounds } from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import PlaceholdImage from '../../../../images/no-image.svg';

import 'leaflet.markercluster/dist/leaflet.markercluster.js';
// import 'leaflet.providers/leaflet-providers.js';

export default {
  props: {
    results: Array,
  },
  components: {
    LMap,
    LTileLayer,
  },
  data() {
    return {
      zoom: 11,
      jawgAccessToken:
        'TmDDKNMDHHbXFeSZbFVQ6WepGw5oyuazR0tR7UwduLwcQZ1punf7scIVrbClse38',
      attribution:
        '<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      ext: 'png',
      mapOptions: {
        zoomSnap: 0.5,
        minZoom: 3,
        maxZoom: 18,
      },
      mcg: L.markerClusterGroup(), // markerClusterGroup, often the "markers" variable
    };
  },
  computed: {
    mappableResults() {
      return this.results.filter(
        (result) => result.location && result.location.geo,
      );
    },
    markers() {
      return this.mappableResults.map((result) => ({
        title: result.title,
        result,
        latLng: latLng(
          result.location.geo.coordinates[1],
          result.location.geo.coordinates[0],
        ),
      }));
    },
    bounds() {
      return latLngBounds(this.markers.map((marker) => marker.latLng));
    },
    center() {
      return this.bounds.getCenter();
    },
    noLocationCount() {
      return this.results.length - this.mappableResults.length;
    },
    tileUrl() {
      return `https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=${this.jawgAccessToken}`;
    },
  },
  methods: {
    // REDO so we have a collection of MarkerClusters at each different administrative level
    drawMap() {
      this.mcg.clearLayers();
      if (!this.$refs.map || this.mappableResults.length === 0) {
        return;
      }
      this.$refs.map.leafletObject.fitBounds(this.bounds);
      // const markers = L.markerClusterGroup();
      this.markers.forEach((marker) => {
        const { latLng, title, result } = marker;

        const imageSrc = result.thumbUrl || result.imageUrl || PlaceholdImage;

        const popup = `<div class="flex"><div class="min-w-max"><img style="width: 100px;" src="${imageSrc}" /></div><div class="pl-3"><div class="text-lg leading-6">${title}</div><div class="mt-2 text-sm text-gray-500">${result.classification ? result.classification.name : ''}</div><div class="text-sm text-gray-500">${result.date ? result.date.name : ''}</div><div class="mt-2 "><a class="text-xs" href="/works/${result.slug}" target="_blank">View Detail</a></div></div></div>`;
        const mapMarker = L.marker(latLng, { title });
        mapMarker.bindPopup(popup);
        // markers.addLayer(mapMarker);
        this.mcg.addLayer(mapMarker);
      });
      this.$refs.map.leafletObject.addLayer(this.mcg);
    },
  },
  watch: {
    mappableResults: {
      handler() {
        this.drawMap();
      },
      deep: true, // In Vue 3, this is necessary to watch "deep" data, e.g., nested data such as arrays https://michaelnthiessen.com/how-to-watch-nested-data-vue
    },
  },
};
</script>

<style lang="scss" scoped></style>
