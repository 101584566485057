import NotFound from '../NotFound.vue';
import ColorsView from '../views/ColorsView.vue';
import DetailView from '../views/DetailView.vue';
import ElementsView from '../views/ElementsView.vue';
import HomeView from '../views/HomeView.vue';
import MethodologiesView from '../views/MethodologiesView.vue';
import PigmentsView from '../views/PigmentsView.vue';
import SearchView from '../views/SearchView.vue';
import DynamicView from '../views/DynamicView.vue';
import gql from 'graphql-tag';
import { apolloClient } from '../gql/apollo';

// Define the GraphQL query using gql
const GET_SLUG_FROM_RELAY_ID = gql`
  query SlugFromRelayID($relayId: String!) {
    slugFromRelayId(relayId: $relayId)
  }
`;

async function fetchSlugFromRelayId(relayId) {
  try {
    const { data } = await apolloClient.query({
      query: GET_SLUG_FROM_RELAY_ID,
      variables: { relayId },
    });
    return data.slugFromRelayId;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching slug from Relay ID:', relayId, error);
    return null;
  }
}

export default [
  {
    path: '',
    component: HomeView,
    name: 'home-view',
    meta: { title: 'Mapping Color in History' },
    props: { slug: 'home' },
  },
  {
    path: '/search/',
    component: SearchView,
    name: 'search-view',
    meta: { title: 'Search - MCH' },
  },
  {
    path: '/colors/',
    component: ColorsView,
    name: 'colors-view',
    meta: { title: 'Colors - MCH' },
  },
  {
    path: '/elements/',
    component: ElementsView,
    name: 'elements-view',
    meta: { title: 'Elements - MCH' },
  },
  {
    path: '/pigments/',
    component: PigmentsView,
    name: 'pigments-view',
    meta: { title: 'Pigments - MCH' },
  },
  {
    path: '/methodologies/',
    component: MethodologiesView,
    name: 'methodologies-view',
    meta: { title: 'Methodologies - MCH' },
  },
  {
    path: '/works/:identifier',
    component: DetailView,
    name: 'detail-view',
    props: (route) => ({ slug: route.params.identifier }), // Map identifier to slug
    // Handle either a slug (pretty URL) or the older Relay ID
    beforeEnter: async (to, from, next) => {
      const identifier = to.params.identifier;

      // Check if the identifier is a Relay ID (based on the '=' suffix)
      if (identifier.endsWith('=')) {
        const slug = await fetchSlugFromRelayId(identifier);
        if (slug) {
          // If a slug is successfully fetched, redirect to the slug-based URL
          next(`/works/${slug}`);
        } else {
          // If no slug could be fetched, redirect to a 'not found' page or handle accordingly
          next('/not-found');
        }
      } else {
        // The identifier does not end with '==', assuming it's already a slug and proceed
        // No need to change the route, as it's not a Relay ID but directly the slug
        next();
      }
    },
  },
  { path: '/healthcheck', name: 'healthcheck', meta: { title: 'Healthcheck' } },
  {
    path: '/:slug/',
    component: DynamicView,
    name: 'dynamic-view',
    meta: { title: 'Page - MCH' },
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: 'not-found',
    meta: { title: 'Not Found - MCH' },
  },
];
