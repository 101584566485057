<template>
  <div v-if="block.value.image.url" class="news-thumbnail">
    <img
      :src="block.value.image.url"
      :alt="block.value.image.title"
      height="100px"
      width="150px"
    />
  </div>
  <div class="news-text">
    <div class="top h3-title news-h3-title" v-html="block.value.title"></div>
    <div class="middle news-middle" v-html="block.value.text"></div>
    <div
      :class="[
        'bottom',
        'news-button',
        customSlug === 'virtual-exhibition' ? 'virtual-exhibition' : '',
      ]"
    >
      <a v-bind:href="block.value.link"> Read More </a>
    </div>
  </div>
</template>

<script>
import newsimg from '@public/images/sample-news-image.jpg';

export default {
  props: {
    block: Object,
    customSlug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newsimg,
    };
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>
